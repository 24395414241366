<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div>
        <div class="tabs-content-secondblock right-tab">
            <div>
                <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                    <div class="breadcrumbactive ml-2">
                        <div>
                            <span>Profile</span>
                            <div>Reminders</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="reminder-info mr-2" style="margin-left: 10px;">
                <div class="" style="background-color: #fff;border-radius: 0px 6px 6px 0px;">
                    <div class="d-flex reminder-buttons align-items-center">
                        <a class="top-tabs-reminders d-flex align-items-center justify-content-center fw-600"
                            role="tab" aria-controls="basictab" aria-expanded="true"
                            aria-selected="false" :class="{ active: toView === 'birthdayView'}"
                            @click="changeView('birthdayView')">
                            <span>Birthdays</span>
                        </a>
                        <a class="top-tabs-reminders d-flex align-items-center justify-content-center fw-600 mx-40"
                            role="tab" aria-controls="basictab" aria-expanded="true"
                            aria-selected="false" :class="{ active: toView === 'digiappsView'}"
                            @click="changeView('digiappsView')">
                            <span>Digi Apps</span>
                        </a>
                        <a class="top-tabs-reminders d-flex align-items-center justify-content-center fw-600 "
                            role="tab" aria-controls="basictab" aria-expanded="true"
                            aria-selected="false" :class="{ active: toView === 'eventsView'}"
                            @click="changeView('eventsView')">
                            <span>Events</span>
                        </a>
                    </div>
                    <div class="mb-3">
                        <div class="">
                            <div class="">
                                <div class="infoHeader py-4">
                                    <div v-if="toView === 'birthdayView'" class="d-flex justify-content-between align-items-center info-reminder flex-wrap ml-4" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                                        <h6 class="subHeaderTitle content-left my-2 text-white pl-5">
                                            <!-- <i class="crmnew-phone iconsize-16 iconsize" style="font-size: 15px; color: #fff;"></i> -->
                                            <span class="fs-16 fw-600 profile-text">Reminders: Birthdays
                                                <img class="ml-2" src="/static/images/reminder_birthday_icon.svg" width="25" />
                                            </span>
                                        </h6>
                                        <!-- <div v-if="$route.params.id == loggedInUser.user_id" class="ml-3 mr-5 my-2">
                                            <button :disabled="delete_api_call_in_progress || save_api_call_in_progress || upload_in_progress || birthday_data_id.length <= 0" class="btn btn-danger text-capitalize reminders-btn-delete" style="border-radius: 5px !important;padding: 0 10px 0 10px !important;height: 30px;line-height: 2;"
                                                @click="showDeleteConfirm"><i class="icon icon-delete text-white mr-1 fs-16"></i>Delete</button> -->
                                                <!-- <button class="btn btn-new-success pointer ml-3 text-capitalize" :disabled="delete_api_call_in_progress || save_api_call_in_progress || upload_in_progress" style="border-radius: 5px !important;padding: 0 10px 0 10px !important;height: 30px;line-height: 2;" @click="addBirthday">
                                                    <i class="icon icon-plus-circle text-white mr-1 fs-16"></i> Add Birthday</button> -->
                                        <!-- </div> -->
                                    </div>
                                    <div class="mt-2" v-if="toView === 'birthdayView'">
                                        <div class="d-flex align-items-center justify-content-center w-100"
                                            v-if="ajaxCallinprogress">
                                            <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae"
                                                style="margin: 0 auto;" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100"
                                            v-if="!birthday_data.length && !ajaxCallinprogress">
                                            <span v-if="!adding_birthday" class="fw-600 fs-18">No Birthdays Found!</span>
                                        </div>
                                        <!-- <div @scroll="infiniteScrollBirthdays" style="overflow-x: hidden; height: 300px; width: 100%;"> -->
                                        <div @scroll="scrollEndBirthday" style="overflow-x: hidden; width: 100%; padding: 0 15px;padding-bottom: 30px;">
                                            <!-- <div v-if="$route.params.id == loggedInUser.user_id" class="row">
                                                <div class="col-12" style="align-items:flex-end">
                                                    <span style="float:right;">
                                                        <button v-if="birthday_data.length > 0" style="border-radius: 18px !important;" class="btn btn-danger pointer"
                                                            @click="DeleteAll">- Delete All</button>
                                                            <button class="btn btn-new-secondary btn-new-color pointer ml-3" style="border-radius: 5px !important;" @click="addBirthday"
                                                            :disabled="add_birthdays.length == 1">+ Add Birthday</button>
                                                    </span>
                                                </div>
                                            </div> -->
                                            <!-- <div class="d-flex justify-content-end px-2" v-if="$route.params.id == loggedInUser.user_id">
                                                <button v-if="birthday_data.length > 0" style="border-radius: 18px !important;" class="btn btn-danger pointer"
                                                    @click="DeleteAll">- Delete All</button>
                                                <button class="btn btn-secondary pointer ml-3" style="border-radius: 5px !important;" @click="addBirthday"
                                                    :disabled="add_birthdays.length == 1">+ Add Birthday</button>
                                            </div> -->
                                            <div class="row">
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 my-4" v-for="(data,index) in birthday_data" :key="index">
                                                    
                                                    <div class="new-reminders-card d-flex flex-row py-2 pr-3 position-relative">
                                                        <!-- <el-checkbox v-if="$route.params.id == loggedInUser.user_id" class="position-absolute" @change="birthdayChecked($event, data)" style="top:-3px; left:-1px" v-model="data.is_checked"></el-checkbox> -->
                                                        <div class="mx-3 d-flex align-items-center">
                                                            <img :src="data.profile_pic.length ? data.profile_pic : 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
                                                                style="width: 40px; border-radius: 40px; height: 40px;" />
                                                        </div>
                                                        <div class="d-flex flex-column w-100" style="overflow: hidden;justify-content:center;">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <el-tooltip class="item" effect="dark" :content="data.name"
                                                                    placement="top">
                                                                    <span class="text-capitalize"
                                                                        style="line-height: 20px; font-size: 15px; font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                                                        {{ data.name }}</span>
                                                                </el-tooltip>
                                                                <span
                                                                    style="line-height: 20px; font-size: 13px;" v-if="data.date_of_birth">{{ (moment(data.date_of_birth).format('D MMM'))}}</span>
                                                                    <span
                                                                    style="line-height: 20px; font-size: 13px;" v-else>-</span>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <span
                                                                    style="line-height: 20px; font-size: 12px;">{{ data.relationship || "-" }}</span>
                                                                <div v-if="$route.params.id == loggedInUser.user_id" class="d-flex" >
                                                                    <span
                                                                        @click="editBirthday(data, index)"
                                                                        :disabled="edit_birthdays.length == 1"><i class="icon icon-pencil new-blue pointer fs-16 pointer"></i></span>
                                                                    <span @click="showDeleteConfirm(data.id)"><i class="icon icon-delete new-blue pointer fs-16 pointer ml-2" ></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div v-if="edit_birthday" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-1">
                                                    <div class="new-reminders-card p-3">
                                                        <div class="text-center">
                                                            <p>Edit Birthday</p>
                                                        </div>
                                                        <hr>
                                                        <div class="my-2 text-center"
                                                            style="">
                                                            <img :src="edit_birthday_data.profile_pic.length ? edit_birthday_data.profile_pic : 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
                                                                style="width: 50px; border-radius: 40px; height: 50px;" />
                                                            <el-dropdown trigger="click"
                                                                style="position: absolute; margin-top: 30px;margin-left: -15px; width: 14px;">
                                                                <span class="el-dropdown-link">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/pic-options-icon.svg"
                                                                        style="height: 12px;" />
                                                                </span>
                                                                <el-dropdown-menu slot="dropdown"
                                                                    style="border-radius: 0 !important">
                                                                    <el-dropdown-item class="mx-1"
                                                                        style="box-shadow: 0 0.5px rgba(0, 0, 0, 0.33)">
                                                                        <span
                                                                            @click="deletePic">
                                                                            <img class="mr-2"
                                                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/trash-red.svg"
                                                                                style="height: 12px;" />
                                                                            Remove Photo
                                                                        </span>
                                                                    </el-dropdown-item>
                                                                    <el-dropdown-item class="mx-1">
                                                                        <label class="pointer">
                                                                            <input type="file"
                                                                                accept="image/x-png, image/gif, image/jpeg" @change="uploadAttachment" />
                                                                            <img class="mr-2"
                                                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/gallery-icon.svg"
                                                                                style="height: 12px;" />
                                                                            Upload from Gallery
                                                                        </label>
                                                                    </el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                        </div>
                                                        <div class="d-flex flex-column w-100">
                                                            <div class="position-relative">
                                                                <div class="d-flex align-items-center">
                                                                    <label for="name"
                                                                        style="text-align:left;font-weight:600;margin-top:0px;margin-bottom:0px;padding-top: 10px !important;width: 68px; min-width: 68px;">
                                                                        Name <span class="text-danger">*</span></label>
                                                                    <input type="text" v-model="edit_birthday_data.name" v-validate="'required'" class="mt-2 input-field"
                                                                        style="border-radius:5px;padding-left:10px;padding-bottom: 4px !important;" name="add_name" maxlength="30"
                                                                        placeholder="Enter Name">
                                                                </div>
                                                                <div class="d-flex align-items-center" :class="{'justify-content-between':errors.has('add_name'),'justify-content-end':!errors.has('add_name')}">
                                                                    <span class="invalid-feedback-form" style="margin-top: 4px !important;color: #ff3b3b;" v-show="errors.has('add_name')">{{ errors.first("add_name") }}</span>
                                                                    <span class="float-right">{{(edit_birthday_data.name).length}}/30</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="d-flex align-items-center">
                                                                    <label for="date_of_birth"
                                                                        style="text-align:left;font-weight:600;margin-bottom:0px; width: 68px;padding-top: 10px !important;min-width: 68px;">Birthday<span class="text-danger pl-1">*</span></label>
                                                                    <date-picker v-model="edit_birthday_data.date_of_birth_formatted"
                                                                        :first-day-of-week="7" :not-after="new Date()"
                                                                        name="date_of_birth"
                                                                        value-type="format"
                                                                        v-validate="'required'"
                                                                        :popup-style="{ top: '100%' }"
                                                                        class="mt-2 mb-0 mx-datepicker profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0"
                                                                        placeholder='MM/DD/YYYY' style="padding-left:10px;padding-bottom: 0px !important;padding-left: 0px !important;"
                                                                        format="MM/DD/YYYY" lang="en" :clearable="false"
                                                                        :editable="false" confirm confirm-text="APPLY">
                                                                    </date-picker>
                                                                </div>
                                                                <span class="invalid-feedback-form" style="margin-top: 4px !important;color: #ff3b3b;line-height: 2;" v-show="errors.has('date_of_birth')">{{ errors.first("date_of_birth") }}</span>
                                                            </div>
                                                            <div>
                                                                <div class="d-flex align-items-center pt-4">
                                                                    <label for="relationship"
                                                                        style="text-align:left;font-weight:600;margin-bottom:0px;padding-top: 10px !important; width: 65px; min-width: 65px;">Relation</label>
                                                                    <div class="d-flex align-items-center w-100">
                                                                        <multiselect :max-height="200"
                                                                            class="multiselect diginew-multiselect mt-2 mb-0"
                                                                            v-model="edit_birthday_data.relationship" name="relationship"
                                                                            :options="relationship_options" :searchable="true"
                                                                            :loading="dropdown_loading"
                                                                            @open="dropdownsData('relationship')"
                                                                            :close-on-select="true" :show-labels="false"
                                                                            placeholder='Select Relationship'>
                                                                            <template slot="noOptions">
                                                                                <div class="text-secondary text-center fw-600">List
                                                                                    is
                                                                                    Empty</div>
                                                                            </template>
                                                                            <template slot="noResult">
                                                                                <div class="text-secondary text-center fw-600">No
                                                                                    Results Found</div>
                                                                            </template>
                                                                            <template slot="afterList">
                                                                                <div v-observe-visibility="reachedEndOfListDropdown" />
                                                                            </template>
                                                                            <!-- <template slot="afterList">
                                                                                <div class="add-vendor p-2 pointer fw-500">
                                                                                    Add New Relation <span class="text-secondary fw-500">'{{"-"}}'</span>
                                                                                    <i class="icon icon-plus ml-2 mt-1 float-right text-secondary"></i>
                                                                                </div>
                                                                            </template> -->
                                                                        </multiselect>
                                                                        <!-- <span class="pointer" @click="goToSettings">
                                                                            <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2" style="width:20px" alt="">
                                                                            </el-tooltip>
                                                                        </span> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-center align-items-center mt-4">
                                                                <button class="btn btn-outline-secondary" style="border-radius: 5px;"
                                                                    @click="closeEditDialogBox" :disabled="upload_in_progress || save_api_call_in_progress">Cancel</button>
                                                                <button class="btn btn-new-success ml-3" :disabled="upload_in_progress || save_api_call_in_progress"
                                                                    @click="editBirthdaySubmit()">Apply
                                                                        <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -35px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                                                    </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="add_new_birthday" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-1" >
                                                    <div class="new-reminders-card p-3">
                                                        <div class="text-center">
                                                            <p>Add New Birthday</p>
                                                        </div>
                                                        <hr>
                                                        <div class="my-2 text-center"
                                                            style="">
                                                            <img :src="create_birthday.profile_pic ? create_birthday.profile_pic : 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
                                                                style="width: 50px; border-radius: 40px; height: 50px;" />
                                                            <el-dropdown trigger="click"
                                                                style="position: absolute; margin-top: 30px;margin-left: -15px; width: 14px;">
                                                                <span class="el-dropdown-link">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/pic-options-icon.svg"
                                                                        style="height: 12px;" />
                                                                </span>
                                                                <el-dropdown-menu slot="dropdown"
                                                                    style="border-radius: 0 !important">
                                                                    <el-dropdown-item class="mx-1"
                                                                        style="box-shadow: 0 0.5px rgba(0, 0, 0, 0.33)">
                                                                        <span
                                                                            @click="deletePic">
                                                                            <img class="mr-2"
                                                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/trash-red.svg"
                                                                                style="height: 12px;" />
                                                                            Remove Photo
                                                                        </span>
                                                                    </el-dropdown-item>
                                                                    <el-dropdown-item class="mx-1">
                                                                        <label class="pointer">
                                                                            <input type="file"
                                                                                accept="image/x-png, image/gif, image/jpeg" @change="uploadAttachment" />
                                                                            <img class="mr-2"
                                                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/gallery-icon.svg"
                                                                                style="height: 12px;" />
                                                                            Upload from Gallery
                                                                        </label>
                                                                    </el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                        </div>
                                                        <div class="d-flex flex-column w-100">
                                                            <div class="position-relative">
                                                                <div class="d-flex align-items-center">
                                                                    <label for="name"
                                                                        style="text-align:left;font-weight:600;margin-top:0px;margin-bottom:0px;padding-top: 10px !important;width: 68px; min-width: 68px;">
                                                                        Name <span class="text-danger">*</span></label>
                                                                    <input type="text" v-model="create_birthday.name" v-validate="'required'" class="mt-2 mb-0 input-field"
                                                                        style="border-radius:5px;padding-left:10px;" name="add_name" maxlength="30"
                                                                        placeholder="Enter Name">
                                                                </div>
                                                                <div class="d-flex align-items-center" :class="{'justify-content-between':errors.has('add_name'),'justify-content-end':!errors.has('add_name')}">
                                                                    <span class="invalid-feedback-form" style="margin-top: 4px !important;color: #ff3b3b;" v-show="errors.has('add_name')">{{ errors.first("add_name") }}</span>
                                                                    <span class="float-right">{{(create_birthday.name).length}}/30</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="d-flex align-items-center">
                                                                    <label for="date_of_birth"
                                                                        style="text-align:left;font-weight:600;margin-bottom:0px;padding-top: 10px !important; width: 68px; min-width: 68px;">Birthday<span class="text-danger pl-1">*</span></label>
                                                                    <date-picker v-model="create_birthday.date_of_birth_formatted"
                                                                        :first-day-of-week="7" :not-after="new Date()"
                                                                        name="date_of_birth"
                                                                        value-type="format"
                                                                        v-validate="'required'"
                                                                        :popup-style="{ top: '100%' }"
                                                                        class="mt-2 mb-0 mx-datepicker profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0"
                                                                        placeholder='MM/DD/YYYY' style="padding-left:10px;padding-bottom: 0px !important;padding-left: 0px !important;"
                                                                        format="MM/DD/YYYY" lang="en" :clearable="false"
                                                                        :editable="false" confirm confirm-text="APPLY">
                                                                    </date-picker>
                                                                </div>
                                                                <span class="invalid-feedback-form" style="margin-top: 4px !important;color: #ff3b3b;line-height: 2;" v-show="errors.has('date_of_birth')">{{ errors.first("date_of_birth") }}</span>
                                                            </div>
                                                            <div>
                                                                <div class="d-flex align-items-center pt-4">
                                                                    <label for="relationship"
                                                                        style="text-align:left;font-weight:600;margin-bottom:0px; padding-top: 10px !important; width: 65px; min-width: 65px;">Relation</label>
                                                                    <div class="d-flex align-items-center w-100">
                                                                        <multiselect :max-height="200"
                                                                            class="multiselect diginew-multiselect mt-2 mb-0"
                                                                            v-model="create_birthday.relationship" name="relationship"
                                                                            :options="relationship_options" :searchable="true"
                                                                            :loading="dropdown_loading"
                                                                            @open="dropdownsData('relationship')"
                                                                            :close-on-select="true" :show-labels="false"
                                                                            placeholder='Select Relationship'>
                                                                            <template slot="noOptions">
                                                                                <div class="text-secondary text-center fw-600">List
                                                                                    is
                                                                                    Empty</div>
                                                                            </template>
                                                                            <template slot="noResult">
                                                                                <div class="text-secondary text-center fw-600">No
                                                                                    Results Found</div>
                                                                            </template>
                                                                            <template slot="afterList">
                                                                                <div v-observe-visibility="reachedEndOfListDropdown" />
                                                                            </template>
                                                                            <!-- <template slot="afterList">
                                                                                <div class="add-vendor p-2 pointer fw-500">
                                                                                    Add New Relation <span class="text-secondary fw-500">'{{"-"}}'</span>
                                                                                    <i class="icon icon-plus ml-2 mt-1 float-right text-secondary"></i>
                                                                                </div>
                                                                            </template> -->
                                                                        </multiselect>
                                                                        <!-- <span class="pointer" @click="goToSettings">
                                                                            <el-tooltip class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2" style="width:20px" alt="">
                                                                            </el-tooltip>
                                                                        </span> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-center align-items-center mt-4">
                                                                <button class="btn btn-outline-secondary" style="border-radius: 5px;"
                                                                    @click="closeAddDialogBox" :disabled="upload_in_progress || save_api_call_in_progress">Cancel</button>
                                                                <button class="btn btn-new-success ml-3" :disabled="upload_in_progress || save_api_call_in_progress"
                                                                    @click="submitNewBirthday()">Save
                                                                        <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -35px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-1">
                                                    <div class="new-reminders-card pointer p-3">
                                                        <div class="" @click="addBirthday">
                                                            <div class="row mt-2">
                                                                <div class="col-1"></div>
                                                                <div class="col-2">
                                                                    <i class="icon icon-plus text-black" style="font-size: 4rem;padding: 0px;border: 1.5px dashed #000;border-radius: 2px;"></i>
                                                                </div>
                                                                <div class="col-7 mt-1 ml-3">
                                                                    <span class="new-blue" style="font-weight: 550;">Add New Birthday</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5 mb-5 text-center w-100" v-if="loading_scroll_birthdays"
                                            id="spinner_birthdays">
                                            <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae"
                                                style="margin: 0 auto;" />
                                        </div>
                                    </div>
                                    <div class="px-5" v-if="toView === 'eventsView'">
                                        <div class="d-flex align-items-center justify-content-center w-100 mt-5"
                                            v-if="ajaxCallinprogress">
                                            <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae"
                                                style="margin: 0 auto;" />
                                        </div>
                                        <!-- <div class="d-flex align-items-center justify-content-center w-100"
                                            v-if="!ajaxCallinprogress && !event_data[Object.keys(event_data)[0]].length ">
                                            <span class="fw-600 fs-18">No Events Found!</span>
                                        </div> -->
                                        <div v-else @scroll="scrollEndEventList" class="w-100 px-3" style="max-height: 500px;overflow-y: auto;overflow-x: hidden;">
                                            <div>
                                                <div>
                                                    <div class="d-flex align-items-center justify-content-between mt-6 mb-4">
                                                        <span class="new-blue text-capitalize" style="font-weight: 600; font-size: 14px;">{{event_data.month}} {{event_data.year}} Upcoming Events</span>
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <span style="color: #00448b; font-weight: 600; font-size: 18px;">{{selectedDate}}</span>
                                                            <!-- <span style="color: #00448b; font-weight: 600; font-size: 18px;">This Week Events</span> -->
                                                            <div class="d-flex align-items-center position-relative">
                                                                <!-- <div class="mr-4" style="border: 1px solid #00448b !important;border-right: 1px solid #00448b !important; border-radius: 6px !important;"> -->
                                                                <!-- <date-picker v-model="dateRange" type="datetime"
                                                                    placeholder='Select Date Range'
                                                                    :first-day-of-week="7" data-vv-scope="profile_data"
                                                                    data-vv-as="Date Of Birth" name="date_of_birth"
                                                                    class="calendarYear mx-left no-icon with-shortcuts mr-12 "
                                                                    format="DD-MM-YYYY HH:MM A" lang="en" :clearable="false"
                                                                    :editable="false" :minute-step="1" 
                                                                    :popup-style="{ top: '100%', right: 0 }"
                                                                    :shortcuts="datePickShortcuts" confirm range confirm-text="APPLY"
                                                                    @confirm="getEventsFiltered" style="width: 23.5em !important;height: 30px !important;padding-top: 2px !important;">
                                                                </date-picker> -->
                                                                <date-picker v-model="dateRange" type="datetime"
                                                                        placeholder='Select Date Range'
                                                                        :first-day-of-week="7" data-vv-scope="profile_data"
                                                                        data-vv-as="Date Of Birth" name="date_of_birth"
                                                                        class="profile-date-picker calendar-edit-per mr-12"
                                                                        format="DD-MM-YYYY HH:MM A" lang="en" :clearable="false"
                                                                        :editable="false" :minute-step="1" 
                                                                        :popup-style="{ top: '100%', right: 0 }"
                                                                        :shortcuts="datePickShortcuts" confirm range confirm-text="APPLY"
                                                                        @confirm="getEventsFiltered" style="width: 24.5em !important;height: 37px !important;padding-top: 2px !important;border: 1px solid #000 !important;border-radius: 6px !important;">
                                                                    </date-picker>
                                                                <!-- calender-img -->
                                                                <!-- <button class="mr-6"
                                                                    style="cursor: pointer;right: 100px;position: absolute;background: #fff; cursor: pointer;border-radius: 10px;padding-top: 2px !important;">
                                                                    <img src="/static/images/blue-calendar-new.svg"/>
                                                                </button> -->
                                                            <!-- </div> -->
                                                                <button type="button"
                                                                    class="btn btn-new-primary btn-smm d-flex align-items-center float-right mr-2"
                                                                    style="box-shadow: none;border-radius: 6px !important;" @click="resetDate"><img
                                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/reset-icon.svg"
                                                                        style="width: 10px; height: 10px; margin-right: 6px;" />
                                                                    Reset</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center flex-wrap" v-if="event_data.year">
                                                        <div class="my-4 mr-7" style="width: 220px;" v-for="(data,index) in event_data.this_month_events"
                                                            :key="index">
                                                            <div class="events-new-card p-2">
                                                                <div class="d-flex align-items-start justify-content-between">
                                                                    <div>
                                                                        <span class="d-block default-color-event-date" style="font-size: 30px;">{{moment(data.start_date).format('DD') || "-"}}</span>
                                                                        <span class="d-block default-color-event-date fs-14">{{moment(data.start_date).format('MMM') || "-" }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <i v-if="$route.params.id == loggedInUser.user_id" class="icon icon-delete new-blue pointer fs-16 pointer" @click="showEventDelete(data.id)"></i>
                                                                        <i v-if="$route.params.id == loggedInUser.user_id" class="icon icon-pencil new-blue pointer fs-16 pointer ml-2" @click="editEventById(data)"></i>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                                <div class="mt-3">
                                                                    <el-tooltip class="item" effect="dark" :content="data.event_name"
                                                                        placement="top">
                                                                        <div class="default-color-event-data text-capitalize"
                                                                            style="line-height: 20px; font-size: 15px; font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ data.event_name }}</div>
                                                                    </el-tooltip>   
                                                                    <div class="d-flex align-items-center mt-1" style="color: rgba(12, 10, 78, 0.5)">
                                                                        <span class="">Starts</span>
                                                                        <span class="mx-1">{{moment(data.start_date).format('LLLL').split(',')[0] || "-"}}</span>
                                                                        <span >{{convertTime24To12(data.start_time) || "-"}}</span>
                                                                    </div>
                                                                    <!-- <span class="mt-1"
                                                                        style="line-height: 20px; font-size: 13px;">{{ data.event_type }}</span> -->
                                                                    <!-- <el-dropdown trigger="click" :hide-on-click="true"
                                                                        style="width: 10em;">
                                                                        <span>
                                                                            <div class="d-flex align-items-center mt-1"
                                                                                style="line-height: 15px;">
                                                                                <div v-for="(item,index) in data.invitees"
                                                                                    :key="index">
                                                                                    <span v-if="index <= 2">
                                                                                        <img style="width: 15px;"
                                                                                            :src="item.profile_pic" />
                                                                                    </span>
                                                                                    <span v-if="index == 3"
                                                                                        style="line-height: 20px; font-size: 13px;">+{{data.invitees.length - 3}}</span>
                                                                                    <span v-else></span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <el-dropdown-menu slot="dropdown"
                                                                            style="max-height: 10em; overflow-y: auto; border-radius: 0 !important; width: 12em;">
                                                                            <el-dropdown-item
                                                                                v-for="(item,index) in data.invitees"
                                                                                :key="index"
                                                                                class="d-flex align-items-center px-2">
                                                                                <img class="mr-2" style="width: 15px;"
                                                                                    :src="item.profile_pic" />{{item.full_name || "-"}}
                                                                            </el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                    </el-dropdown> -->
                                                                    <div v- class="d-flex px-2 py-1 mt-1" style="color: rgba(12, 10, 78, 0.5);line-height: 1;border-radius: 3px;background-color: #dbf2e8;">
                                                                        <span class="">Remind Before - </span>
                                                                        <div class="d-flex flex-wrap align-items-center">
                                                                            <span v-for="(reminder, index) in data.reminder" :key="index"
                                                                                >{{reminder.number || "-"}}
                                                                                {{reminder.gap || "-"}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="$route.params.id == loggedInUser.user_id" class="my-4 mr-7" style="width: 220px;" >
                                                            <div class="events-new-card-add-new p-2 d-flex flex-column pointer" @click="createEvent">
                                                                <div class="d-flex align-items-center justify-content-center h-100">
                                                                    <div class="text-center">
                                                                        <span style="font-size: 50px !important;font-weight: 200;">+</span>
                                                                        <p class="new-blue fs-16">
                                                                            Add New Event
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr class="mt-5">
                                                <div v-if="event_data.all_events.length > 0">
                                                    <div class="d-flex align-items-center flex-wrap mt-6 mb-4">
                                                        <span class="new-blue" style="font-weight: 600; font-size: 14px;">All Events</span>
                                                    </div>
                                                    <div class="d-flex align-items-center flex-wrap">
                                                        <div class="my-4 mr-7" style="width: 220px;" v-for="(data,index) in event_data.all_events"
                                                            :key="index">
                                                            <div class="events-new-card p-2">
                                                                <div class="d-flex align-items-start justify-content-between">
                                                                    <div>
                                                                        <span class="d-block default-color-event-date" style="font-size: 30px;">{{moment(data.start_date).format('DD') || "-"}}</span>
                                                                        <span class="d-block default-color-event-date fs-14">{{moment(data.start_date).format('MMM') || "-" }}</span>
                                                                    </div>
                                                                    <div>
                                                                        <i v-if="$route.params.id == loggedInUser.user_id" class="icon icon-delete new-blue pointer fs-16 pointer" @click="showEventDelete(data.id)"></i>
                                                                        <i v-if="$route.params.id == loggedInUser.user_id" class="icon icon-pencil new-blue pointer fs-16 pointer ml-2" @click="editEventById(data)"></i>
                                                                    </div>
                                                                </div>
                                                                <hr>
                                                                <div class="mt-3">
                                                                    <el-tooltip class="item" effect="dark" :content="data.event_name"
                                                                        placement="top">
                                                                        <div class="default-color-event-data text-capitalize"
                                                                            style="line-height: 20px; font-size: 15px; font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ data.event_name }}</div>
                                                                    </el-tooltip>   
                                                                    <div class="d-flex align-items-center mt-1" style="color: rgba(12, 10, 78, 0.5)">
                                                                        <span class="">Starts</span>
                                                                        <span class="mx-1">{{moment(data.start_date).format('LLLL').split(',')[0] || "-"}}</span>
                                                                        <span >{{convertTime24To12(data.start_time) || "-"}}</span>
                                                                    </div>
                                                                    <!-- <span class="mt-1"
                                                                        style="line-height: 20px; font-size: 13px;">{{ data.event_type }}</span> -->
                                                                    <!-- <el-dropdown trigger="click" :hide-on-click="true"
                                                                        style="width: 10em;">
                                                                        <span>
                                                                            <div class="d-flex align-items-center mt-1"
                                                                                style="line-height: 15px;">
                                                                                <div v-for="(item,index) in data.invitees"
                                                                                    :key="index">
                                                                                    <span v-if="index <= 2">
                                                                                        <img style="width: 15px;"
                                                                                            :src="item.profile_pic" />
                                                                                    </span>
                                                                                    <span v-if="index == 3"
                                                                                        style="line-height: 20px; font-size: 13px;">+{{data.invitees.length - 3}}</span>
                                                                                    <span v-else></span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                        <el-dropdown-menu slot="dropdown"
                                                                            style="max-height: 10em; overflow-y: auto; border-radius: 0 !important; width: 12em;">
                                                                            <el-dropdown-item
                                                                                v-for="(item,index) in data.invitees"
                                                                                :key="index"
                                                                                class="d-flex align-items-center px-2">
                                                                                <img class="mr-2" style="width: 15px;"
                                                                                    :src="item.profile_pic" />{{item.full_name || "-"}}
                                                                            </el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                    </el-dropdown> -->
                                                                    <div class="d-flex px-2 py-1 mt-1" style="color: rgba(12, 10, 78, 0.5);line-height: 1;border-radius: 3px;background-color: #dbf2e8;">
                                                                        <span class="">Remind Before - </span>
                                                                        <div class="d-flex flex-wrap align-items-center">
                                                                            <span v-for="(reminder, index) in data.reminder" :key="index"
                                                                                >{{reminder.number || "-"}}
                                                                                {{reminder.gap || "-"}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div v-if="!event_data.length == 0" class="text-center w-100 mt-10">
                                                    <img src="/static/images/event-illu.svg" alt="" width="400px">
                                                    <div class="pt-6">
                                                        <span class="fw-600 fs-14" style="color: #676767b5 !important;">No Events Found!</span>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div v-if="event_data[Object.keys(event_data)[0]].length" class="w-100 px-3">
                                                <div class="d-flex align-items-center justify-content-between mt-6 mb-4">
                                                    <span class="new-blue text-capitalize" style="font-weight: 600; font-size: 14px;">Filtered Events</span>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span style="color: #00448b; font-weight: 600; font-size: 18px;">{{selectedDate}}</span>
                                                        <!-- <span style="color: #00448b; font-weight: 600; font-size: 18px;">This Week Events</span> -->
                                                        <div class="d-flex align-items-center position-relative">
                                                            <div class="mr-4" style="border: 1px solid #00448b !important;border-right: 1px solid #00448b !important; border-radius: 6px !important;">
                                                            <date-picker v-model="dateRange" type="datetime"
                                                                placeholder='Select Date Range'
                                                                :first-day-of-week="7" data-vv-scope="profile_data"
                                                                data-vv-as="Date Of Birth" name="date_of_birth"
                                                                class="calendarYear mx-left no-icon with-shortcuts mr-12"
                                                                format="DD-MM-YYYY HH:MM A" lang="en" :clearable="false"
                                                                :editable="false" :minute-step="1" 
                                                                :popup-style="{ top: '100%', right: 0 }"
                                                                confirm range confirm-text="APPLY"
                                                                @confirm="getEventsFiltered" style="width: 23.5em !important;">
                                                            </date-picker>
                                                            <!-- :shortcuts="datePickShortcuts" -->
                                                            <button class="mr-6"
                                                                style="cursor: pointer;right: 100px;position: absolute;background: #fff; cursor: pointer;border-radius: 10px;">
                                                                <img src="http://localhost:8080/static/images/blue-calendar-new.svg"/>
                                                            </button></div>
                                                            <button type="button"
                                                                class="btn btn-new-primary btn-smm d-flex align-items-center float-right mr-2"
                                                                style="box-shadow: none;border-radius: 6px !important;" @click="resetDate"><img
                                                                    src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/reset-icon.svg"
                                                                    style="width: 10px; height: 10px; margin-right: 6px;" />
                                                                Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center flex-wrap">
                                                    <div class="my-4 mr-7" style="width: 220px;" v-for="(data,index) in event_data[Object.keys(event_data)[0]]"
                                                        :key="index">
                                                        <div class="events-new-card d-flex flex-column p-2">
                                                            <div class="d-flex align-items-start justify-content-between">
                                                                <div>
                                                                    <span class="d-block default-color-event-date" style="font-size: 30px;">{{moment(data.start_date).format('DD') || "-"}}</span>
                                                                    <span class="d-block default-color-event-date fs-14">{{moment(data.start_date).format('MMM') || "-" }}</span>
                                                                </div>
                                                                <div>
                                                                    <i v-if="$route.params.id == loggedInUser.user_id" class="icon icon-delete new-blue pointer fs-16 pointer" @click="showEventDelete(data.id)"></i>
                                                                    <i v-if="$route.params.id == loggedInUser.user_id" class="icon icon-pencil new-blue pointer fs-16 pointer ml-2" @click="editEventById(data)"></i>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="mt-3">
                                                                <el-tooltip class="item" effect="dark" :content="data.event_name"
                                                                    placement="top">
                                                                    <div class="default-color-event-data text-capitalize"
                                                                        style="line-height: 20px; font-size: 15px; font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ data.event_name }}</div>
                                                                </el-tooltip>   
                                                                <div class="d-flex align-items-center mt-1" style="color: rgba(12, 10, 78, 0.5)">
                                                                    <span class="">Starts</span>
                                                                    <span class="mx-1">{{moment(data.start_date).format('LLLL').split(',')[0] || "-"}}</span>
                                                                    <span >{{data.start_time || "-"}}
                                                                        {{data.ampm}}</span>
                                                                </div>
                                                                <div class="d-flex px-2 py-1 mt-1" style="color: rgba(12, 10, 78, 0.5);line-height: 1;border-radius: 3px;background-color: #dbf2e8;">
                                                                    <span class="">Remind Before - </span>
                                                                    <div class="d-flex flex-wrap align-items-center">
                                                                        <span v-for="(reminder, index) in data.reminder" :key="index"
                                                                            >{{reminder.number || "-"}}
                                                                            {{reminder.gap || "-"}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="$route.params.id == loggedInUser.user_id" class="my-4 mr-7" style="width: 220px;" >
                                                        <div class="events-new-card-add-new p-2 d-flex flex-column pointer" @click="createEvent">
                                                            <div class="d-flex align-items-center justify-content-center h-100">
                                                                <div class="text-center">
                                                                    <span style="font-size: 50px !important;font-weight: 200;">+</span>
                                                                    <p class="new-blue fs-16">
                                                                        Add New Event
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="event_data.all_events.length == 0" class="text-center w-100 mt-5">
                                                <img src="/static/images/event-illu.svg" alt="" width="400px">
                                                <div class="pt-6">
                                                    <span class="fw-600 fs-14" style="color: #676767b5 !important;">You don't have any Events created! </span>
                                                </div>
                                                <div class="pt-1">
                                                    <span class="fw-500 fs-14" style="color: #676767b5 !important;">Please click to Add New event </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5 mb-5 text-center w-100" v-if="loading_scroll_events"
                                            id="spinner_events">
                                            <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae"
                                                style="margin: 0 auto;" />
                                        </div>
                                        <!-- <div v-if="$route.params.id == loggedInUser.user_id" class="row w-100 align-items-center justify-content-end">
                                            <div class="">
                                                <span style="float:right;margin-right:10px;">
                                                    <button class="btn btn-new-secondary" @click="createEvent()">
                                                        + Create Event
                                                    </button>
                                                </span>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="mt-2 px-5" v-if="toView === 'digiappsView' && !showAppNotifications">
                                        <div class="">
                                            <span class="fw-600 fs-15 my-8 text-secondary">Click the highlighted notification count to
                                                get more details</span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-if="apps.length <= 0">
                                            <span class="fs-14 font-weight-bold">DigiApps not found</span>
                                        </div>
                                        <div v-else @scroll="infinite_scroll" class=""
                                            style="overflow-x: hidden; height: 380px;width:100%;">
                                            <div class="d-flex align-items-center flex-wrap">
                                                <div class="mt-6 mr-5" style="min-width: 100px; width: 100px" v-for="(app,index) in apps" :key="index">
                                                    <div class="digi-apps-new-card">
                                                        <div>
                                                            <img :src="app.logo_blue" height="20" />
                                                        </div>
                                                        <div class=" mt-1">
                                                            <span class="fw-600 fs-13"
                                                                style="color: #051b32;">{{ app.product_name }}</span>
                                                        </div>
                                                        <div v-if="app.count > 0" @click="getNotifications(app)"
                                                            class="d-flex align-items-center justify-content-center notificationNumber"
                                                            style="right: 0;top: -10px;">
                                                            <span>{{ app.count }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5 mb-5 text-center w-100" v-if="loading_scroll" id="spinner">
                                            <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae"
                                                style="margin: 0 auto;" />
                                        </div>
                                    </div>
                                    <div class="row mt-10" v-if="toView === 'digiappsView' && showAppNotifications">
                                        <div class="col-12">
                                            <div class="row d-flex align-items-center fw-600 fs-15 mb-4">
                                                <div class="col-3"></div>
                                                <div class="col-6 d-flex align-items-center justify-content-center">
                                                    <img class="mr-4" :src="notifications_data_logo" height="40" />
                                                    {{ notifications_data_prodname }}
                                                </div>
                                                <div class="col-3 d-flex align-items-center justify-content-end">
                                                    <button class="btn btn-new-primary btn-smm d-flex align-items-center" @click="showAppNotifications = false"><i class="icon-chevron-left"></i> Back</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-6">
                                            <div @scroll="infinite_scroll1" style="overflow-x: hidden; max-height: 300px; height: 100%;">
                                                <table class="table appNotificationsTable">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                TITLE
                                                            </th>
                                                            <th>
                                                                CREATED TIME
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr >
                                                            <td colspan="2">
                                                                <div class="d-flex align-items-center justify-content-center w-100" v-if="ajaxCallinprogress_notify && !notifications_data.length">
                                                                    <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae" style="margin: 0 auto;" />
                                                                </div>
                                                                <div class="d-flex align-items-center justify-content-center w-100" v-if="!ajaxCallinprogress_notify && !notifications_data.length">
                                                                    <span>No data found</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-for="(notifications,index) in notifications_data" :key="index">
                                                            <td v-html="notifications.message" style="width: 70%;">
                                                                
                                                            </td>
                                                            <td>
                                                                {{moment(notifications.created_date).format("DD/MM/YYYY")}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-if="loading_scroll1" class="mt-5 mb-5" id="spinner1">
                                                <half-circle-spinner :animation-duration="1000" :size="50" color="#005dae" style="margin: 0 auto;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-event @hideCreateEvent="hideCreateEventdata" @eventCreatedSuccess="eventCreatedSuccess" :edit_create="edit_create_text" :event_id="event_id" v-if="createevent" modal_name="create-event"></create-event>
        <add-birthday-popup v-if="is_add_new_birthday" :mode="mode" modal_name="birthday-popup" @hideSelf="hideSelf" ></add-birthday-popup>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <delete-confirm-popup v-if="delete_confirm" @hideConfirm="hideConfirm" type="Birthdays" @deleteConfirm="deleteConfirm" modal_name="delete_confirm" ></delete-confirm-popup>
        <event-delete-popup v-if="event_delete" @hideEventConfirm="hideEventConfirm" @deleteEventConfirm="deleteEventConfirm" modal_name="event_delete" ></event-delete-popup>
    </div>
</template>

<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import moment from "moment";
    import profile from "../mixins/profile";
    import { SweetModal } from 'sweet-modal-vue';
    import CreateEvent from "./CreateEvent.vue";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    const {
        uuid
    } = require('uuidv4');
    import axios from 'axios';
    import AddBirthdayPopup from './AddBirthdayPopup.vue';
    import globals from '../globals';
    import DeleteConfirmPopup from './DeleteConfirmPopup.vue';
    import EventDeletePopup from './EventDeletePopup.vue';
    import time_utils from '../mixins/time_utils.js'
    export default {
        mixins: [profile, time_utils],
        components: {
            HalfCircleSpinner,
            CreateEvent,
            SweetModal,
            AddBirthdayPopup,
            DeleteConfirmPopup,
            EventDeletePopup,
            HollowDotsSpinner
        },
        data() {
            return {
                warning_msg: '',
                success_msg: '',
                toView: "birthdayView",
                createevent: false,
                adding_birthday: false,
                is_add_new_birthday: false,
                add_birthdays: [],
                edit_birthdays: [],
                edit_events: [],
                birthdays: [],
                events: [],
                apps: [],
                count_scroll: 0,
                loading_scroll: false,
                count_scroll1: 0,
                loading_scroll1: false,
                notifications_data: [],
                pickDate: false,
                dateRange: "",
                datePickShortcuts: [{
                    //     text: "Today",
                    //     onClick: () => this.shortcut("Today")
                    // },
                    // {
                    //     text: "This Week",
                    //     onClick: () => this.shortcut("This Week")
                    // },
                    // {
                    //     text: "This Month",
                    //     onClick: () => this.shortcut("This Month")
                    // },
                    // {
                    //     text: "This Year",
                    //     onClick: () => this.shortcut("This Year")
                    },
                ],
                relationship_options: [],
                dropdown_loading: false,
                selectedDate: null,
                showAppNotifications: false,
                ajaxCallinprogress: false,
                ajaxCallinprogress_notify: false,
                notifications_data_prodname: '',
                notifications_data_logo: '',
                product_id: '',
                bottom: false,
                bottom1: false,
                edit_events_data: [],
                loading_scroll_birthdays: false,
                count_scroll_birthdays: 0,
                loading_scroll_events: false,
                count_scroll_events: 0,

                // new states
                // Birthdays
                edit_birthday_index: 0,
                skip: 0,
                limit: 10,
                search_key: "",
                skip_relationship:0,
                limit_relationship:10,
                which_type: '',
                isVisible: false,
                scrollable: false,
                count_relationship: 0,
                edit_birthday: false,
                add_new_birthday: false,
                create_birthday: {
                    date_of_birth: "",
                    name: "",
                    profile_pic: "",
                    relationship: "",
                    date_of_birth_formatted: ''
                },
                edit_birthday_data: {
                    date_of_birth: "",
                    name: "",
                    profile_pic: "",
                    relationship: "",
                    id: '',
                    date_of_birth_formatted:''
                },
                birthday_data: [],
                // Events
                event_skip: 0,
                event_data: {
                },
                start_date: '',
                end_date: '',
                event_between: '',
                filter_applied: false,
                edit_create_text: '',
                event_id: '',
                save_api_call_in_progress:false,
                delete_api_call_in_progress:false,
                birthday_data_id: [],
                upload_in_progress: false,
                mode: 'add',
                delete_confirm: false,
                event_list_scroll_count: 0,
                birthday_delete: '',
                event_delete: false,
                delete_event: '',
                events_data: [],
            }
        },
        methods: {
            moment(date) {
                return moment(date);
            },
            hideEventConfirm(){
                event_delete = false;
            },
            showEventDelete(id) {
                this.delete_event = id
                this.event_delete = true;
                setTimeout(() => {
                    this.$modal.show('event_delete');
                }, 500);
            },
            deleteEventConfirm() {
                if(this.delete_event === undefined || this.delete_event === null || this.delete_event === '') {
                    this.event_delete = false;
                } else {
                    this.removeEvent(this.delete_event);
                }
            },
            async removeEvent(id) {
                try {
                    let param = id;
                    let response = await this.deleteEvents(param);
                    if(response.status_id == 1) {
                        this.success_msg = response.message;
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getAllEvents();
                        }, 1000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 1000);
                }
            },
            hideConfirm() {
                this.delete_confirm = false;
            },
            showDeleteConfirm(id) {
                this.birthday_delete = id
                this.delete_confirm = true;
                setTimeout(() => {
                    this.$modal.show('delete_confirm');
                }, 500);
            },
            deleteConfirm() {
                // this.DeleteAll();
                if(this.birthday_delete === undefined || this.birthday_delete === null || this.birthday_delete === '') {
                    this.delete_confirm = false;
                } else {
                    this.deleteBirthdayById(this.birthday_delete);
                }
            },
            birthdayChecked(event, data) {
                if(event) {
                    data.is_checked = true
                    this.birthday_data_id.push(data.id);
                    this.birthday_data_id = [...new Set(this.birthday_data_id)]
                } else {
                    data.is_checked = false
                    let dat_index = this.birthday_data_id.findIndex((data) => data === data.id)
                    this.birthday_data_id.splice(dat_index, 1)
                    this.birthday_data_id = [...new Set(this.birthday_data_id)]
                }
                this.$forceUpdate();
            },
            goToSettings(){
                let reminders = true
                let routeData = this.$router.resolve({path: `/settings/profile?reminders=${reminders}`});
                window.open(routeData.href, '_blank');
            },
            changeView(view) {
                this.toView = view;
                if (view === "digiappsView") {
                    this.$emit("showDigiApps");
                } else {
                    this.$emit("hideDigiApps");
                }
            },
            hideCreateEventdata(){
                this.createevent = false;
                // setTimeout(() => {
                //     this.getAllEvents();
                // }, 500);
            },
            createEvent(){
                this.createevent= true;
                this.edit_create_text = "CREATE";
                setTimeout(() => {
                    this.$modal.show('create-event')
                },500);
            },
            eventCreatedSuccess() {
                this.createevent = false;
                this.getAllEvents();
            },
            shortcut(type) { 
                this.event_between = type;
                switch (type) {
                    case "Today":
                        this.dateRange = [new Date(), new Date()];
                        this.selectedDate = "Today's Events";
                        this.getEventsFiltered("from_shortcuts");
                        break;
                    case "This Week":
                        let startDateWeek = new Date().getDate() - new Date().getDay();
                        let lastDateWeek = startDateWeek + 6;
                        this.dateRange = [new Date(new Date().setDate(startDateWeek)), new Date(new Date().setDate(
                            lastDateWeek))];
                        this.selectedDate = "This Week's Events";
                        this.getEventsFiltered("from_shortcuts");
                        break;
                    case "This Month":
                        let startDateMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                        let lastDateMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                        this.dateRange = [startDateMonth, lastDateMonth];
                        this.selectedDate = "This Month's Events";
                        this.getEventsFiltered("from_shortcuts");
                        break;
                    case "This Year":
                        let startDateYear = new Date(new Date().getFullYear(), 0, 1);
                        let lastDateYear = new Date(new Date().getFullYear(), 11, 31);
                        this.dateRange = [startDateYear, lastDateYear];
                        this.selectedDate = new Date().getFullYear();
                        this.getEventsFiltered("from_shortcuts");
                        break;
                    default:
                        break;
                }
            },
            editBirthday(data, index) {
                if(this.add_new_birthday == true || this.edit_birthday == true) {
                    this.is_add_new_birthday = true;
                    this.mode = 'edit'
                    setTimeout(() => {
                        this.$modal.show('birthday-popup');
                    }, 500);
                } else {
                    this.edit_birthday_data.name = data.name;
                    this.edit_birthday_data.relationship = data.relationship;
                    this.edit_birthday_data.profile_pic = data.profile_pic;
                    this.edit_birthday_data.date_of_birth = data.date_of_birth;
                    this.edit_birthday_data.date_of_birth_formatted = data.date_of_birth_formatted;
                    this.edit_birthday_data.id = data.id;
                    this.edit_birthday_index = index;
                    this.edit_birthday = true;
                }
            },
            resetDate() {
                this.dateRange = "";
                // this.selectedDate = new Date().getFullYear();
                this.start_date = '';
                this.end_date = '';
                this.event_between = '';
                this.getAllEvents();
            },
            // async removeEvent(type, selectedEvent) {
            //     try {
            //         let id = selectedEvent.id;
            //         let response = await this.deleteEvents(id);
            //         if(response.status_id == 1) {
            //             this.success_msg = response.message;
            //             this.$refs.success_modal.open();
            //             setTimeout(() => {
            //                 this.$refs.success_modal.close();
            //                 this.getAllEvents();
            //             }, 1000);
            //         }
            //     }
            //     catch(err) {
            //         // console.log(err);
            //     }
            // },
            addBirthday() {
                if(this.add_new_birthday == true || this.edit_birthday == true) {
                    this.mode = 'add'
                    this.is_add_new_birthday = true;
                    setTimeout(() => {
                        this.$modal.show('birthday-popup');
                    }, 500);
                } else {
                    this.add_new_birthday = true;
                    this.adding_birthday = true;
                    this.create_birthday = {
                        date_of_birth: "",
                        date_of_birth_formatted: '',
                        name: "",
                        profile_pic: "",
                        relationship: "",
                    };
                }
            },
            hideSelf() {
                this.is_add_new_birthday = false;
            },
            closeAddDialogBox() {
                this.add_new_birthday = false;
                this.adding_birthday = false;
                this.create_birthday = {
                    date_of_birth: "",
                    date_of_birth_formatted: '',
                    name: "",
                    profile_pic: "",
                    relationship: "",
                };
            },

            closeEditDialogBox(){
                this.edit_birthday = false;
            },

            async DeleteAll() {
                try {
                    let param = {
                        id: this.birthday_data_id
                    }
                    this.delete_api_call_in_progress = true
                    let response = await this.deleteBirthdayMultiple(param);
                    if(response.status_id == 1) {
                        this.getBirthdays()
                        this.birthday_data_id = []
                        this.delete_api_call_in_progress = false
                        this.delete_confirm = false;
                        setTimeout(() => {
                            this.$modal.hide('delete_confirm');
                        }, 500);
                    }
                }
                catch(err) {
                    this.delete_api_call_in_progress = false
                    this.delete_confirm = false;
                    setTimeout(() => {
                        this.$modal.hide('delete_confirm');
                    }, 500);
                }
            },

            applyBirthdayRem(field_common_id, field_profile_pic, field_name, field_date_of_birth, field_relationship) {
                this.birthdays.push({
                    common_id: field_common_id,
                    profile_pic: field_profile_pic,
                    name: field_name,
                    date_of_birth: field_date_of_birth,
                    relationship: field_relationship,
                })
                this.add_birthdays = []
            },

            ApplyEditedBirthdayEvent(edited_id, edited_profile_pic, edited_name, edited_date_of_birth,
                edited_relationship) {
                this.birthdays.forEach((birthdayEvent) => {
                    if (birthdayEvent.common_id === edited_id) {
                        birthdayEvent.profile_pic = edited_profile_pic
                        birthdayEvent.name = edited_name
                        birthdayEvent.date_of_birth = edited_date_of_birth
                        birthdayEvent.relationship = edited_relationship
                    }
                })
                this.edit_birthdays = []
            },
            async getEventsFiltered(from_shortcuts) {
                let from_shortcuts_data = JSON.parse(JSON.stringify(from_shortcuts))
                let filtered_date = [];
                if(typeof(from_shortcuts_data) == 'array' || typeof(from_shortcuts_data) == 'object') {
                    filtered_date = from_shortcuts_data.filter(function (el) {
                        return el != null;
                    });
                }
                if((filtered_date.length > 0) || from_shortcuts == 'from_shortcuts') {
                    this.start_date = moment(this.dateRange[0]).format().split(' ')[0]+'Z';
                    this.end_date = moment(this.dateRange[1]).format().split(' ')[0]+'Z';
                    this.filter_applied = true;
                    this.getAllEvents();
                    console.log("hello")

                }
                // if (!from_shortcuts) {
                //     this.start_date = moment(new Date(new Date(this.dateRange[0]).getTime() + 1000 * 60 * 60 * 24)).format().split(' ')[0]+'Z';
                //     this.end_date = moment(new Date(new Date(this.dateRange[1]).getTime() + 1000 * 60 * 60 * 24)).format().split(' ')[0]+'Z';
                // } else {
                //     this.start_date = moment(this.dateRange[0]).format().split(' ')[0]+'Z';
                //     this.end_date = moment(this.dateRange[1]).format().split(' ')[0]+'Z';
                // }
            },
            async infiniteScrollEvents(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    try {
                        this.loading_scroll_events = true
                        let params = {
                            skip: this.count_scroll_events,
                            search_key: this.search_key,
                            limit: this.limit,
                        }
                        let res = await this.$http.post("/events/list", params)
                        if (res.data.skip == this.count_scroll_events) {
                            let tempFiles = res.data.response
                            tempFiles.forEach(el => {
                                this.events.push(el)
                            })
                            this.events.forEach(x => {
                                if ("start_date_time" in x) {
                                    if (x.start_date_time.date && x.start_date_time.time) {
                                        let startDate = x.start_date_time.date;
                                        let pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                                        let formattedDate = new Date(startDate.replace(pattern,
                                        '$3-$2-$1'));
                                        let date = startDate.split("/");
                                        let time = x.start_date_time.time.split(":");
                                        Object.assign(x.start_date_time, {
                                            month: formattedDate.toLocaleString('default', {
                                                month: 'short'
                                            }),
                                            dateNum: date[0],
                                            day: formattedDate.toLocaleString('default', {
                                                weekday: 'long'
                                            }),
                                            ampm: time[0] >= 12 ? "pm" : "am",
                                        });
                                    }
                                }
                                if (!"event_schedule" in x) {
                                    Object.assign(this.events, {
                                        event_schedule: {
                                            start_date: "",
                                            end_date: "",
                                            all_day: false,
                                        }
                                    });
                                }
                                if (x.event_schedule && x.event_schedule == {}) {
                                    x.event_schedule = {
                                        start_date: "",
                                        end_date: "",
                                        all_day: false,
                                    }
                                }
                                if (!"repeat_cycle" in x) {
                                    Object.assign(this.events, {
                                        repeat_cycle: {
                                            start: "",
                                            end: "",
                                            cycle: {
                                                number: 0,
                                                gap: "",
                                            }
                                        }
                                    });
                                }
                                if (x.repeat_cycle && x.repeat_cycle == {}) {
                                    x.repeat_cycle = {
                                        start: "",
                                        end: "",
                                        cycle: {
                                            number: 0,
                                            gap: "",
                                        }
                                    }
                                }
                                if (!"remind_before" in x) {
                                    Object.assign(this.events, {
                                        remind_before: [{
                                            number: 0,
                                            gap: "",
                                        }]
                                    });
                                }
                                if (x.remind_before && x.remind_before.length === 0) {
                                    x.remind_before.push({
                                        number: 0,
                                        gap: "",
                                    });
                                }
                            });
                            this.events = this.events.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.events.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.count_scroll_events += res.data.response.length
                            this.loading_scroll_events = false
                        }
                        if (res.data.response.length < 10) {
                            document.getElementById('spinner_events').style.display = 'none'
                            this.loading_scroll_events = false
                        }
                    } catch (reason) {

                    }

                }
            },
            async getNotifications(data) {
                this.product_id = data._id;
                this.showAppNotifications = true;
                this.ajaxCallinprogress_notify = true;
                this.notifications_data_prodname = data.product_name;
                this.notifications_data_logo = data.logo_blue;
                try {
                    let params = {
                        limit: this.limit,
                        skip: this.skip,
                        product_id: this.product_id
                    }
                    let res = await this.$http.post('/notifications/list', params);
                    if (res.data.status_id === 1) {
                        this.notifications_data = res.data.response;
                        this.count_scroll1 = res.data.response.length;
                    }
                    this.ajaxCallinprogress_notify = false;
                } catch (error) {

                }
            },
            async getDigiApps() {
                try {
                    let params = {
                        limit: this.limit,
                        skip: this.skip,
                        search_key: this.search_key
                    }
                    let pram;
                    if(this.loggedInUser.company_id == '' || this.loggedInUser.company_id == null) {
                        pram = `/products/list?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&all_products=${true}`
                    } else {
                        pram = `/products/list?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`
                    }
                    let res = await this.$http.get( globals.AUTH_SERVICE + pram);
                    if (res.data.status_id === 1) {
                        console.log(res.data);
                        this.apps = res?.data?.response || [];
                        this.count_scroll = res.data.response.length;
                    }
                } catch (err) {

                }
            },
            async infinite_scroll(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    //this code will run when the user scrolls to the bottom of this div so 
                    //you could do an api call here to implement lazy loading
                    try {
                        this.loading_scroll = true
                        let params = {
                            limit: this.limit,
                            skip: this.count_scroll,
                            search_key: this.search_key
                        }
                        let pram;
                        if(this.loggedInUser.company_id == '' || this.loggedInUser.company_id == null) {
                            pram = `/products/list?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&all_products=${true}`
                        } else {
                            pram = `/products/list?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`
                        }
                        let res = await this.$http.get( globals.AUTH_SERVICE + pram);
                        // let res = await this.$http.get( globals.AUTH_SERVICE + `/products/list?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&all_products=${true}`);
                        if (res.data.skip == this.count_scroll) {
                            let tempFiles = res?.data?.response || []
                            tempFiles.forEach(el => {
                                this.apps.push(el)
                            })
                            this.apps = this.apps.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.apps.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.count_scroll += res.data.response.length
                            this.loading_scroll = false
                        }
                        if (res.data.response.length < 10) {
                            document.getElementById('spinner').style.display = 'none'
                            this.loading_scroll = false
                        }
                    } catch (reason) {

                    }
                    this.bottom = true;

                }
            },
            async infinite_scroll1(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    //this code will run when the user scrolls to the bottom of this div so 
                    //you could do an api call here to implement lazy loading
                    try {
                        this.loading_scroll1 = true
                        let params = {
                            skip: this.count_scroll1,
                            limit: this.limit,
                            product_id: this.product_id
                        }
                        let res = await this.$http.post('/notifications/list', params)
                        if (res.data.skip == this.count_scroll1) {
                            let tempFiles = res.data.response
                            tempFiles.forEach(el => {
                                this.notifications_data.push(el)
                            })
                            this.notifications_data = this.notifications_data.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.notifications_data.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.count_scroll1 += res.data.response.length
                            this.loading_scroll1 = false
                        }
                        if (res.data.response.length < 10) {
                            document.getElementById('spinner1').style.display = 'none'
                            this.loading_scroll1 = false
                        }
                    } catch (reason) {

                    }
                    this.bottom1 = true;

                }
            },



            uploadAttachment(e) {
                var input = e.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                    name: input.files[0].name,
                    size: JSON.stringify(input.files[0].size),
                    source: source,
                    progress: 0,
                    created_time: new Date(),
                    error: "",
                    };
                    this.getResumableURL(input.files[0], attachment);
                }
            },
            getResumableURL(file, attachment) {
                let vuethis = this;
                let data = {
                    file: attachment.name,
                };
                data = new FormData();
                data.append("file", file);
                this.upload_in_progress = true
                this.$http.post("https://cloudapis.digicollect.com/external/upload_file", data).then(response => {
                    this.success_msg = 'Profile Uploaded Successfully.'
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        // this.hideSelf();
                        this.create_birthday.profile_pic = response.data.url;
                        this.edit_birthday_data.profile_pic = response.data.url;
                        // this.$emit("uploaded", response.data.url)
                        this.upload_in_progress = false
                    }, 2000);
                }).catch(error => {
                    // console.log(error.data.reason);
                    this.upload_in_progress = false
                });
            },
            deletePic() {
                try {
                    this.$swal({
                        title: 'Alert',
                        text: "Are you sure want to delete ?",
                        type: 'warning',
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText:'YES',
                        cancelButtonText:'NO'
                    }).then((result) => {
                        if (result.value) {
                            this.create_birthday.profile_pic = ''
                            this.edit_birthday_data.profile_pic = ''
                            this.$refs.delete_modal.open();;
                            setTimeout(() => {
                                this.$refs.delete_modal.close();
                                // this.hideSelf();
                                // this.$emit("deletePic");
                                this.create_birthday.profile_pic = 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png';
                            }, 2000);
                        }
                    })
                } catch (reason) {
                    console.log(reason)
                }
            },

            // Birthday's Api call methods
            async getBirthdays() {
                this.ajaxCallinprogress = true;
                try {
                    let skip = this.skip;
                    let response = await this.getBirthdayList(skip);
                    if(response.status_id == 1) {
                        this.birthday_data = response.response;
                        this.birthday_data.forEach((data, d_index) => {
                            data['is_checked'] = false
                            data['date_of_birth_formatted'] = data.date_of_birth.split('-')[1]+'/'+data.date_of_birth.split('-')[2]+'/'+data.date_of_birth.split('-')[0]
                        })
                    }
                    this.birthday_scroll_count = response.response.length;
                    this.save_api_call_in_progress = false;
                    this.ajaxCallinprogress = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async scrollEndBirthday(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    //this code will run when the user scrolls to the bottom of this div so 
                    //you could do an api call here to implement lazy loading
                    try {
                        this.save_api_call_in_progress = true
                        let skip = this.birthday_scroll_count;
                        let res = await this.getBirthdayList(skip);
                        this.save_api_call_in_progress = false;
                        this.ajaxCallinprogress = false;
                        if (res.skip == this.birthday_scroll_count) {
                            let tempFiles = res.response
                            tempFiles.forEach(el => {
                                this.birthday_data.push(el)
                            })
                            this.birthday_data = this.birthday_data.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.birthday_data.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.birthday_data.forEach((data, d_index) => {
                                data['is_checked'] = false
                                data['date_of_birth_formatted'] = data.date_of_birth.split('-')[1]+'/'+data.date_of_birth.split('-')[2]+'/'+data.date_of_birth.split('-')[0]
                            })
                            this.birthday_scroll_count += res.response.length
                            this.save_api_call_in_progress = false
                        }
                        if (res.response.length < 10) {
                            // document.getElementById('spinner').style.display = 'none'
                            this.save_api_call_in_progress = false
                        }
                    } catch (reason) {

                    }
                }
            },
            async createNewBirthday() {
                this.$validator.validateAll().then(async result => {
                    if (result && this.create_birthday.date_of_birth_formatted != '') {
                        try {
                            this.save_api_call_in_progress = true;
                            let params = {
                                name: this.create_birthday.name,
                                relationship: this.create_birthday.relationship,
                                date_of_birth: this.create_birthday.date_of_birth_formatted ? this.create_birthday.date_of_birth_formatted.split('/')[2]+'-'+this.create_birthday.date_of_birth_formatted.split('/')[0]+'-'+this.create_birthday.date_of_birth_formatted.split('/')[1] : '',
                                profile_pic: this.create_birthday.profile_pic
                            };
                            let response = await this.createBirthday(params);
                            if(response.status_id == 1) {
                                this.success_msg = response.message;
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.add_new_birthday = false;
                                    this.getBirthdays();
                                }, 1000);
                            } else {

                            }
                        }
                        catch(err) {
                            // console.log(err);
                            this.warning_msg = err;
                            this.$refs.warning_modal.open();
                            setTimeout(() => {
                                this.$refs.warning_modal.close();
                                // this.save_api_call_in_progress = false;
                            }, 1000);
                        }
                    }
                });
            },
            submitNewBirthday() {
                this.createNewBirthday();
            },
            async editBirthdaySubmit() {
                this.$validator.validateAll().then(async result => {
                    if (result && this.edit_birthday_data.date_of_birth_formatted != '') {
                        try {
                            this.save_api_call_in_progress = true;
                            let param = {
                                name: this.edit_birthday_data.name,
                                relationship: this.edit_birthday_data.relationship,
                                date_of_birth: this.edit_birthday_data.date_of_birth_formatted ? this.edit_birthday_data.date_of_birth_formatted.split('/')[2]+'-'+this.edit_birthday_data.date_of_birth_formatted.split('/')[0]+'-'+this.edit_birthday_data.date_of_birth_formatted.split('/')[1] : '',
                                profile_pic: this.edit_birthday_data.profile_pic,
                            }
                            if(this.edit_birthday_data.id) {
                                param.id = this.edit_birthday_data.id;
                            }
                            let response = await this.updateBirthday(param, this.edit_birthday_data.id);
                            if(response.status_id == 1) {
                                this.success_msg = response.message;
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.edit_birthday = false;
                                    this.getBirthdays();
                                }, 1000);
                            }
                        }
                        catch(err) {
                            // console.log(err);
                            this.warning_msg = err;
                            this.$refs.warning_modal.open();
                            setTimeout(() => {
                                this.$refs.warning_modal.close();
                            }, 1000);
                            this.save_api_call_in_progress = true;
                        }
                    }
                });
            },
            async deleteBirthdayById(id) {
                try {
                    let param = id;
                    let response = await this.deleteBirthday(param);
                    if(response.status_id == 1) {
                        this.success_msg = response.message;
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getBirthdays();
                        }, 1000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 1000);
                }
            },
            // Event's Api call methods
            async getAllEvents() {
                this.ajaxCallinprogress = true;
                try {
                    let param = {
                        skip: 0,
                        limit: this.limit,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        event_between: this.event_between
                    };
                    let response = await this.getEventsList(param);
                    if(response.status_id == 1) {
                        this.event_data = response.response;
                        // this.event_data = events_data;
                        this.event_list_scroll_count = this.events_data.length
                        // response?.response?.all_events?.length || 0
                    }
                    this.ajaxCallinprogress = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async scrollEndEventList(e) {
                const {
                    target
                } = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    console.log("fvhuejbvedh")
                    console.log(this.event_list_scroll_count)
                    //this code will run when the user scrolls to the bottom of this div so 
                    //you could do an api call here to implement lazy loading
                    try {
                        let param = {
                            skip: this.event_list_scroll_count,
                            limit: this.limit,
                            start_date: this.start_date,
                            end_date: this.end_date,
                            event_between: this.event_between
                        };
                        let res = await this.getEventsList(param);
                        if (res.skip == this.event_list_scroll_count) {
                            let tempFiles = res.response
                            tempFiles.all_events.forEach(el => {
                                this.event_data.all_events.push(el)
                            })
                            tempFiles.this_month_events.forEach(el => {
                                this.event_data.this_month_events.push(el)
                            })
                            this.event_data.all_events = this.event_data.all_events.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.event_data.all_events.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.event_data.this_month_events = this.event_data.this_month_events.filter((thing, index) => {
                                const payload = JSON.stringify(thing);
                                return index === this.event_data.this_month_events.findIndex(obj => {
                                    return JSON.stringify(obj) === payload;
                                });
                            });
                            this.event_list_scroll_count += res.response.all_events.length
                        }
                    } catch (reason) {

                    }
                }
            },
            editEventById(data) {
                this.event_id = ''
                this.event_id = data.id;
                this.createevent = true;
                this.edit_create_text = "EDIT";
                setTimeout(() => {
                    this.$modal.show('create-event')
                },500);
            },
            async dropdownsData(type) {
                this.which_type = type;
                if(type == 'relationship') {
                    this.edit_room_type = true;
                }
                this.dropdown_loading = true;
                try {
                    let params = {
                        type: type,
                        skip: this.skip_relationship,
                        limit: this.limit_relationship
                    };
                    let response = await this.getDropdownsData(params);
                    if(response.status_id == 1) {
                        if(type == 'relationship') {
                            this.relationship_options = response.response;
                        }
                    }
                    this.dropdown_loading = false;
                    this.count_relationship = response.response.length;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async reachedEndOfListDropdown(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        this.dropdown_loading = true;
                        let params = {
                            limit: 10,
                            type: this.which_type,
                            skip: this.count_relationship
                        };
                        let response = await this.getDropdownsData(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count_relationship) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                if(this.which_type == 'relationship') {
                                    tempFiles.forEach(el => {
                                        this.relationship_options.push(el);
                                    });
                                }
                                this.count_relationship += response.response.length;
                                this.dropdown_loading = false
                            } else {
                                this.scrollable = true
                                this.dropdown_loading = false
                            }
                        }
                        this.dropdown_loading = false
                    }
                }
            },
        },
        mounted() {
            this.$on("hideCreateEvent", () => {
                this.createevent = false;
                setTimeout(() => {
                    this.$modal.hide('create-event')
                },500);
            })
            this.getBirthdays();
            this.getAllEvents();
            this.getDigiApps();
        },
        created() {
            const dict = {
                custom: {
                    add_name:{
                        required: () => "Please enter Name",
                    },
                    edit_name:{
                        required: () => "Please enter Name",
                    },
                    date_of_birth: {
                        required: () => "Please select Date of Birth"
                    }
                }
            };
            this.$validator.localize("en", dict);
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    }
</script>

<style scoped>
    .diginew-multiselect, .input-field, .input-border, .vue-tel-input {
        border-radius: 0px !important;
        border: solid 0px #3f3f3f !important;
        border-bottom: solid 0.5px #cdd2da !important;
        background-color: #fff;
        padding: 4px 10px !important;
        width: 100% !important;
        /* height: 38px; */
    }
    .btn-outline-secondary {
        color: #e82828 !important;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff !important;
        background-color: #e82828 !important;
        border-color: #e82828 !important;
    }
    .top-tabs-reminders {
        border-bottom: 4px solid #f4f5f9;
        color: #808495 !important;
        font-size: 15px;
        cursor: pointer;
        width: 150px;
        padding: 10px 0;
    }

    .top-tabs-reminders:hover {
        border-bottom: 4px solid #00448b;
        color: #00448b !important;
    }

    .active {
        border-bottom: 4px solid #00448b;
        /* background: #00448b; */
        color: #00448b !important;
    }
    .btn-new-color {
        background: #00448b
    }

    .dataTab {
        /* border: 1px solid #707070; */
        border-radius: 6px;
        color: #3f3f3f;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.41);
        height: 10em;
        margin-left: 8px;
        margin-right: 8px;
    }

    .dataTab1 {
        /* border: 1px solid #707070; */
        border-radius: 6px;
        color: #3f3f3f;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.41);
        /* height: 25em; */
        margin-left: 8px;
        margin-right: 8px;
    }

    .greenChip {
        background: rgb(18, 136, 7) none repeat scroll 0% 0%;
        color: rgb(255, 255, 255);
        border-radius: 20px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        text-align: center;
        width: 5.2em;
        margin-right: 5px;
    }

    .blueCalendarTop {
        background: #005dae;
        color: #fff;
        font-weight: 600;
        border: 3px solid #abc1e5;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
    }

    .blueCalendarBottom {
        background: #005dae;
        color: #fff;
        font-weight: 800;
        border: 3px solid #abc1e5;
        border-radius: 0px 0px 5px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.3em;
        font-size: 15px;
        border-top: 0;
    }

    .notificationNumber {
        position: absolute;
        top: 8px;
        font-size: 18px;
        background: #f5a623;
        border-radius: 50%;
        padding: 10px;
        height: 30px;
        width: 30px;
        cursor: pointer;
    }

    .appNotificationsTable thead tr th {
        background: #00448b;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        padding: 0rem 4rem !important;
    }

    .appNotificationsTable tbody tr td {
        color: #000000;
        font-size: 12px;
        padding: 1rem 4rem !important;
    }

    .appNotificationsTable tbody tr {
        box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.16);
    }

    .label {
        margin-bottom: 0px
    }
    .text-blue{
        color: #005dae!important;
    }
    .new-reminders-card {
        /* padding: 0 12.3px 11px 0; */
        border-radius: 4px;
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 0.5px #0d84f2;
        background-color: #ffff;
        width: 274px !important;
    }
    .digi-apps-new-card {
        padding: 10px 9.3px 7.3px 8.3px;
        border-radius: 5px;
        border: solid 0.5px #00448b;
        background-color: #eeeff3;
    }
    .reminder-buttons {
        padding: 10px 51.5px 0 51.5px;
        border-radius: 6px;
        background-color: #f4f5f9;
    }
    .events-new-card {
        border-radius: 3px;
        background-color: #edf4ff;
        min-height: 175px;
        max-height: 175px;
        height: 175px;
    }
    .events-new-card-add-new {
        border-radius: 3px;
        border: dashed 2px #707070;
        background-color: #edf4ff;
        min-height: 175px;
        max-height: 175px;
        height: 175px;
    }
    .default-color-event-data {
        color: #0c0a4e;
    }
@media only screen and (min-width: 0px) and (max-width: 350px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .reminder-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-reminder{
        width: 100% !important;
        overflow: hidden !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -16px !important;
    }
    .infoHeader{
        width: 100% !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .reminder-buttons{
        flex-direction: column;
    }
}
@media only screen and (min-width:351px) and (max-width: 400px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .reminder-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-reminder{
        width: 100% !important;
        overflow: hidden !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -16px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
    .reminder-buttons{
        flex-direction: column;
    }
}
@media only screen and (min-width:401px) and (max-width: 500px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .reminder-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-reminder{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -16px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
    .reminder-buttons{
        flex-direction: column;
    }
}
@media only screen and (min-width:501px) and (max-width: 768px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .reminder-info{
        background-color: #fff !important;
        margin-left: -5px !important;
    }
    .info-reminder{
        width: 100% !important;
    }
    .content-left{
        margin-left: -10px !important;
    }
    .content-lefts{
        margin-left: -16px !important;
    }
    .infoHeader{
        width: 100% !important;
        overflow: hidden !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
    .reminder-buttons{
        flex-direction: column;
    }
}
::placeholder{
    color: #adadad !important;
    font-weight: 400 !important;
    font-size: 14px;
}
.mx-datepicker::placeholder{
    color: #adadad !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.btn-new-primary {
    color: #fff;
    border-radius: 5px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-transform: none;
    font-weight: 500;
    line-height: 30px;
    box-shadow: none;
    background-color: #00448b;
    border-color: 2px solid #00448b;
    box-shadow: none !important;
    font-weight: 500;
}
.mx-input-append{
    display: none;
}

</style>